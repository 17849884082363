<template>
  <div class="w-full flex justify-center items-center">
    <!-- Content -->
    <div v-if="content"><component :is="content" /></div>
  </div>
</template>

<script>
import { defineAsyncComponent, computed } from "vue";
import useQuickscreen from "../../../composables/useQuickscreen";
export default {
  setup() {
    const { getQuickscreenContent } = useQuickscreen();
    const content = computed(() => {
      if (getQuickscreenContent.value) {
        return defineAsyncComponent(() =>
          import(`../content/${getQuickscreenContent.value}.vue`)
        );
      }
      return null;
    });
    return {
      content,
    };
  },
};
</script>

<style></style>
